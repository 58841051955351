import React from 'react';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { JournalistStat } from 'mediadb-models';
import { defaultChartOptions, isSeriesHidden } from '@/components/charts-options';

export default function JournalistStats({stats}: {
    stats: JournalistStat[]
}) {
    const chartTitle = 'Journalists Counts';

    const journalistsStatsOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            type: 'line',
            ...defaultChartOptions.chart,
        },
        title: {
            text: chartTitle
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            gridLineDashStyle: 'Dash',
            allowDecimals: false,
            title: {
                text: 'Journalists Counts'
            }
        },
        series: [
            {
                type: 'line',
                name: 'Journalists',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalists
                })),
            },
            {
                type: 'line',
                name: 'Journalists MR Tier1 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsMrVerified
                })),
            },
            {
                type: 'line',
                name: 'Journalists MR Tier2 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsMrTier2Verified
                })),
            },
            {
                type: 'line',
                name: 'Journalists Articles Tier1 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsArticlesVerified
                })),
            },
            {
                type: 'line',
                name: 'Journalists Articles Tier2 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsArticlesTier2Verified
                })),
            },
            {
                type: 'line',
                name: 'Journalists Tier1 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsVerified
                })),
            },
            {
                type: 'line',
                name: 'Journalists Tier2 Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsTier2Verified
                })),
            },
            {
                type: 'line',
                name: 'Journalists Verified',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: (s.journalistsVerified || 0) + (s.journalistsTier2Verified || 0)
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Email',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithEmail
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Linkedin',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithLinkedin
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Facebook',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithFacebook
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Instagram',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithInstagram
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Twitter',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithTwitter
                })),
            },
            {
                type: 'line',
                name: 'Journalists With Outlet',
                data: stats.map(s => ({
                    x: new Date(s.createdAt).getTime(),
                    y: s.journalistsWithOutlet
                })),
            },
        ].map(s => ({
            ...s,
            visible: !isSeriesHidden(chartTitle, s.name)
        } as SeriesOptionsType))
    };

    return <HighchartsReact highcharts={Highcharts} options={journalistsStatsOptions}/>;
}

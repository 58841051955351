import React, { useEffect, useState } from 'react';
import * as journalistSearchLogService from '@/services/journalist-search-log.service';
import { Box, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { JournalistStat, KeyValue } from 'mediadb-models';
import { getJournalistStats } from '@/services/journalist-stat.service';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import QueryTookAverage from '@/components/journalist-charts/query-took-average';
import QueryCount from '@/components/journalist-charts/query-count';
import JournalistParamsCount from '@/components/journalist-charts/journalist-params-count';
import JournalistSortCount from '@/components/journalist-charts/journalist-sort-count';
import JournalistTopicsCount from '@/components/journalist-charts/journalist-topics-count';
import JournalistStats from '@/components/journalist-charts/journalist-stats';
import EmailStatus from '@/components/journalist-charts/email-status';
import { DatePicker } from '@mui/x-date-pickers';
import { subDays } from 'date-fns';

export default function JournalistCharts() {
    const [journalistUserQueryCount, setJournalistUserQueryCount] = useState<KeyValue[]>([]);
    const [userJournalistCount, setUserJournalistCount] = useState<KeyValue[]>([]);

    const [journalistStats, setJournalistStats] = useState<JournalistStat[]>([]);
    const [fromDate, setFromDate] = useState<Date>(subDays(new Date(), 30));

    useEffect(() => {
        journalistSearchLogService.getUserQueryCount(fromDate).then(setJournalistUserQueryCount);
        journalistSearchLogService.getUserJournalistCount(fromDate).then(setUserJournalistCount);

        getJournalistStats(fromDate).then(s => {
            setJournalistStats(s.sort((s1, s2) => s1.createdAt.toString().localeCompare(s2.createdAt.toString())));
        });
    }, [fromDate])

    const onFromDateChanged = date => {
        setFromDate(date);
    }

    const userQueryCountColumns: GridColDef<KeyValue>[] = [
        {
            field: 'key',
            headerName: 'User',
            flex: 1,
        },
        {
            field: 'value',
            headerName: 'Queries',
            flex: 1,
        },
    ];

    const userJournalistCountColumns: GridColDef<KeyValue>[] = [
        {
            field: 'key',
            headerName: 'User',
            flex: 1,
        },
        {
            field: 'value',
            headerName: 'Journalists',
            flex: 1,
        },
    ];

    return <Stack direction="column" spacing={5} width="100%">
        <DatePicker<Date> label="From date"
            value={fromDate}
            onChange={onFromDateChanged}/>
        <JournalistStats stats={journalistStats}/>
        <Stack direction="row" spacing={5} useFlexGap flexWrap="wrap" width="100%" alignItems="center">
            <EmailStatus stats={journalistStats}/>
            <QueryTookAverage fromDate={fromDate}/>
            <QueryCount fromDate={fromDate}/>
            <JournalistParamsCount fromDate={fromDate}/>
            <JournalistSortCount fromDate={fromDate}/>
            <JournalistTopicsCount fromDate={fromDate}/>
        </Stack>
        <Stack direction="row" spacing={5} useFlexGap flexWrap="wrap">
            <Box flexGrow={1} maxWidth="600px">
                <Card>
                    <CardHeader
                        title="Journalist Query Count"
                    />
                    <CardContent>
                        <DataGrid<KeyValue> columns={userQueryCountColumns}
                            sx={{
                                flexGrow: 1
                            }}
                            rows={journalistUserQueryCount}
                            hideFooterPagination={true}
                            rowCount={journalistUserQueryCount.length}
                            disableColumnFilter={true}
                            getRowId={(row) => row.key}
                        />
                    </CardContent>
                </Card>
            </Box>
            <Box flexGrow={1} maxWidth="600px">
                <Card>
                    <CardHeader
                        title="Journalists Count"
                    />
                    <CardContent>
                        <DataGrid<KeyValue> columns={userJournalistCountColumns}
                            sx={{
                                flexGrow: 1
                            }}
                            rows={userJournalistCount}
                            hideFooterPagination={true}
                            rowCount={userJournalistCount.length}
                            disableColumnFilter={true}
                            getRowId={(row) => row.key}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Stack>
    </Stack>
}

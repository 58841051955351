import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import {
    BrandingWatermark,
    Business,
    Email, Home,
    LinkedIn,
    Merge,
    Person, PersonSearch,
    PsychologyAlt,
    Rule,
    TaskAlt, Tune
} from '@mui/icons-material';
import LoginButton from '@/components/login-button';
import { useRouter } from 'next/router';
import { useAuth } from 'react-oidc-context';

const drawerWidth = 240;
const barHeight = 64;

export default function Navbar({children}) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const router = useRouter();
    const auth = useAuth();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const routes = [
        {
            href: '/',
            icon: <Home/>,
            text: 'Home',
        },
        {
            href: '/contact',
            icon: <Person/>,
            text: 'Contacts',
        },
        {
            href: '/anewstip-journalist',
            icon: <Person/>,
            text: 'Anewstip Journalists'
        },
        {
            href: '/email-stat',
            icon: <Email/>,
            text: 'Email Stats',
        },
        {
            href: '/linkedin-journalist',
            icon: <LinkedIn/>,
            text: 'Linkedin Journalists'
        },
        {
            href: '/linkedin-url-search',
            icon: <LinkedIn/>,
            text: 'Linkedin Url Search'
        },
        {
            href: '/mr-journalist',
            icon: <BrandingWatermark/>,
            text: 'MR Journalists',
        },
        {
            href: '/journalist',
            icon: <Person/>,
            text: 'Journalists',
        },
        {
            href: '/journalist-missing',
            icon: <PersonSearch/>,
            text: 'Missing Journalists',
            divider: <Divider/>,
        },
        {
            href: '/outlet-home',
            icon: <Home/>,
            text: 'Outlets',
        },
        {
            href: '/linkedin-outlet',
            icon: <LinkedIn/>,
            text: 'Linkedin Outlets',
        },
        {
            href: '/mr-outlet',
            icon: <BrandingWatermark/>,
            text: 'MR Outlets',
        },
        {
            href: '/anewstip-outlet',
            icon: <Business/>,
            text: 'Anewstip Outlets'
        },
        {
            href: '/outlet-attributes',
            icon: <PsychologyAlt/>,
            text: 'AI Outlets',
        },
        {
            href: '/outlet',
            icon: <Business/>,
            text: 'Outlets',
        },
        {
            href: '/outlet-missing',
            icon: <Business/>,
            text: 'Missing Outlets',
        },
        {
            href: '/outlet-duplicate',
            icon: <Business/>,
            text: 'Outlet Duplicates',
            divider: <Divider/>,
        },
        {
            href: '/job',
            icon: <TaskAlt/>,
            text: 'Jobs'
        },
        {
            href: '/settings',
            icon: <Tune/>,
            text: 'Settings',
        },
        {
            href: '/manual-merge',
            icon: <Merge/>,
            text: 'Manual merge',
        }
    ];

    const currentRoute = routes.find(r => r.href !== '/' && router.pathname?.startsWith(r.href));

    const drawer = auth.isAuthenticated ? (
        <div>
            {/*<Toolbar/>*/}
            {/*<Divider/>*/}
            <List>
                {routes.map(route =>
                    <React.Fragment
                        key={route.href}>
                        <ListItem disablePadding>
                            <Link href={route.href}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {route.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={route.text}/>
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        {route.divider}
                    </React.Fragment>
                )}
            </List>
        </div>
    ) : null;

    return (
        <Box sx={{display: 'flex'}} height="100vh">
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    height: {sm: `${barHeight}px`},
                    ml: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    <Box width="100%"
                        display="flex"
                        justifyContent="space-between">
                        <Box>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2, display: {sm: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                {currentRoute?.icon} {currentRoute?.text || router.pathname} - Media DB Manager
                            </Typography>
                        </Box>
                        <LoginButton/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    height: {sm: `calc(100% - ${barHeight}px)`},
                }}
            >
                <Toolbar/>
                {children}
            </Box>
        </Box>
    );
}
